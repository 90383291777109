<template>
  <section class="content">
    <div class="card card-info">
      <div class="card-header">
        <h3 class="card-title">Daftar Pergerakan Saldo</h3>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-hover" ref="tblsaldoapprove">
            <thead>
              <tr>
                <th>TANGGAL</th>
                <th>Shipper</th>
                <th>TIPE</th>
                <th>DESKRIPSI</th>
                <th>JUMLAH</th>
                <th>STATUS</th>
                <th>INVOICE ID</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
export default {
  nama: "Daftar Saldo Approval",
  data() {
    return {
      errors: [],
      method: "",
      //   form: {
      //     jumlah: "",
      //   },
      //   saldo: {},
      //   total: "",
      roles: "",
      role_id: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "confirm") {
          Swal.fire({
            title: "Konfirmasi?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              authFetch("/payment/admin/approval/" + e.target.dataset.id, {
                method: "PUT",
                body: "id=" + e.target.dataset.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.tbl.api().ajax.reload();
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.tbl.api().ajax.reload();
                  }
                });
            }
          });
        }
        console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    this.tbl = createTable(e.tblsaldoapprove, {
      title: "",
      roles: this.$route.params.roles,
      selectedRowClass: "",
      serverSide: true,
      frame: false,
      displayLength: 100,
      lengthChange: false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      ajax: "/payment/admin/approval",
      columns: [
        { data: "deposit_date" },
        { data: "shipper_name" },
        { data: "trans_type" },
        { data: "note" },
        { data: "debit" },
        { data: "status" },
        { data: "invoice_code" },
        { data: "invoice_code", sortable:false },
      ],
      filterBy: [1, 6],
      rowCallback: function (row, data) {
        if (data.trans_type == 0) {
          $("td:eq(2)", row).html(
            `<span class="badge badge-info">TOP UP</span>`
          );
          $("td:eq(4)", row).html("Rp " + formatCurrency(data.debit));
        } else if (data.trans_type == 11) {
          $("td:eq(2)", row).html(
            `<span class="badge badge-danger">WITHDRAW</span>`
          );
          $("td:eq(4)", row).html("Rp " + formatCurrency(data.credit));
        } else if (data.trans_type == 1) {
          $("td:eq(2)", row).html(
            `<span class="badge badge-info">REKONSILIASI</span>`
          );
          var jumlah = data.debit;
          if(jumlah==0)jumlah=-data.credit;
          $("td:eq(4)", row).html("Rp " + formatCurrency(jumlah));
        } else {
          $("td:eq(2)", row).html("");
        }

        if (data.status == 0) {
          $("td:eq(5)", row).html(
            `<span class="badge badge-warning">PENDING</span>`
          );
        } else {
          $("td:eq(5)", row).html(
            `<span class="badge badge-success">SUKSES</span>`
          );
        }

        if (data.status == 1) {
          $("td:eq(7)", row).html("");
        } else {
          $("td:eq(7)", row).html(
            `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="confirm" data-id="` +
              data.invoice_code +
              `"><i class="fas fa-check"></i> Konfirmasi</button>
              </div>`
          );
        }
      },
    });
  },
};
</script>
<style scoped>
</style>